.grid_item_main {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20rem;
}

.grid_item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    width: 80%;
}

.grid_item_main {
    transition: transform .3s ease;
}

.grid_item_main:hover {
    transform: scale(90%);
}

.grid_item_image {
    width: 100%;
    object-fit: cover;
    margin-bottom: 2rem;
    border-radius: 10px;
}