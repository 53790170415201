.general__div_works {
    padding: 5rem;
}

.works--main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    margin-bottom: 5rem;
}

.works--main-heading {
    text-align: center;
    margin-bottom: 5rem;
}

.works__works_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 770px) {
    .works__main_div {
        max-width: 130ch;
        margin: auto;
    }
}

@media screen and (max-width: 770px) {
}

@media screen and (max-width: 550px) {
    .works__works_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .general__div_works {
        padding: 1rem;
    }}
