.header__main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header__text {
    border-radius: 5px;
    padding: 0.5rem 2rem;
    margin-top: 15rem;
}

.header__text p {
    font-size: 0.9em;
    font-family: var(--font-family-two);
}

.header__my_information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 9rem;
}

.header__about_me {
    margin-top: 3rem;
    margin-right: 5rem;
}

.header__about_me p {
    margin: 1rem 0;
    font-weight: normal;
    font-size: 0.8em;
}

.header__profile_picture {
    width: 20rem;
}

.header__my_photo:hover {
    transform: rotate(360deg);
    transition: 400ms ease all;
}

.header__amy_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3rem 9rem;
}

.header__amy_container_text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 1rem;
}

.header__amy_arrow {
    margin-left: 1rem;
}

.header__amy_like_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.header__like_button {
    margin-top: 1rem;
}

.header__like_counter {
    font-size: 4em;
    margin-top: 1rem;
}

.header__amy_like_button_text {
    margin-bottom: 1rem;
}

@media screen and (min-width: 770px) {
    .header__main_div {
        max-width: 130ch;
        margin: auto;
    }
}

@media screen and (max-width: 770px) {
    .header__main_div {
        flex-direction: column;
    }

    .header__my_information {
        flex-direction: column;
    }

    .header__about_me {
        margin-bottom: 2rem;
    }
    .header__amy_arrow {
        display: none;
    }

    .header__amy_container_text {
        margin-bottom: 2rem;
    }

    .header__amy_container {
        flex-direction: column;
    }

    .header__profile_picture {
        width: 15rem;
    }
}

@media screen and (max-width: 550px) {
    .header__amy_container {
        margin: 4rem 0;
    }

    .header__my_information {
        margin: 1rem;
    }
}