.general__div_bio {
    padding: 5rem;
}

.bio__main_div {
    max-width: 130ch;
    margin: auto;
}

.bio_my_story {
    margin: 0 9rem;
}

.bio_my_story_title {
    margin-bottom: 2rem;
}

.bio_my_story p {
    margin: 1rem 0;
    font-size: 1em;
}

.bio_my_story_link:hover {
    color: #DA0037;
}

.bio_my_story_date {
    font-size: 1.3em;
}

.bio_my_story_text_box {
    margin-left: 1rem;
    padding: 1rem;
}

.bio_my_story_text {
    margin-bottom: 1rem;
    font-size: 0.8em;
}

@media screen and (max-width: 786px) {
    .general__div_bio {
        padding: 1rem;
    }

    .bio__main_div {
        Width: 100%;
    }

    .bio_my_story {
        margin: 0;
    }

    .bio_my_story_title {
        margin-bottom: 1rem;
    }

    .bio_my_story p {
        margin: 0;
        font-size: 1em;
    }

    .bio_my_story_link:hover {
        color: #DA0037;
    }

    .bio_my_story_date {
        font-size: 1.3em;
        margin-bottom: 2rem;
    }

    .bio_my_story_text_box {
        margin: 0;
        padding: 1rem;
    }

    .bio_my_story_text {
        margin-bottom: 1rem;
        font-size: 0.8em;
    }
}