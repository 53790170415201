@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,400;0,600;1,100;1,200;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200;300&display=swap');

:root {
  --font-family: 'Roboto mono', sans-serif;
  --font-family-two: 'Source code pro';

  --color-dark: #171717;
  --color-medium: #444444;
  --color-light: #EDEDED;
  --color-cyberCherry: #DA0037;

  --color-petrol: #1a202c;
  --color-petrol-light: #313c53;
}