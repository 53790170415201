.main__footer_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    margin: auto;
}

.footer__copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.footer__copyright p {
    font-size: 0.7em;
    margin: 0.3rem 0.4rem;
}
 .footer__copyright_logo {
     display: flex;
     justify-content: center;
     align-items: center;
 }