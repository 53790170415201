* {
    font-family: "Roboto Mono", serif;
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    padding: 0;
    color: ivory;
}

div {
    margin: 0;
    padding: 0;
}

a {
    color: unset;
    text-decoration: none;
}

@media screen and (max-width: 700px) {
    .section__margin {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}
