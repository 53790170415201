.general__div_contact {
    padding: 5rem;
}

.contact__main_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15rem;
    margin-bottom: 20rem;
}

.contact__main_heading {
    text-align: center;
    margin-bottom: 3rem;
}

.contact__form-control input {
    margin-bottom: 2rem;
    color: #171717;
}

input::placeholder {
    color: #171717;
}

textarea::placeholder {
    color: #171717;
}

.contact__send_input {
    margin-top: 2rem;
}

.contact__send_input:hover {
    transform: scale(1.2);
    background-color: #8D7E71;
    color: ivory;
}

@media screen and (min-width: 770px) {
}

@media screen and (max-width: 770px) {
}

@media screen and (max-width: 550px) {
    .general__div_contact {
        padding: 1rem;
    }
}