.general__div_tech {
    padding: 5rem;
}

.tech__main_div {
    max-width: 130ch;
    margin: 0 auto;
}

.tech__tech_div {
    margin: 0 9rem;
}

.tech__heading_div {
    margin-bottom: 2rem;
}

.tech__subtxt {
    margin-top: 2rem;
    margin-bottom: 5rem;
}

.tech__logos_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
}

.tech__logo_div {
    width: 6rem;
    margin: 0.5rem;
}

@media screen and (min-width: 770px) {
}

@media screen and (max-width: 770px) {
}

@media screen and (max-width: 550px) {
    .tech__main_div {
        margin: auto;
    }

    .tech__tech_div {
        margin: auto;
    }

    .general__div_tech {
        padding: 1rem;
    }
}